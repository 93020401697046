import { useAuth } from "@/auth/AuthProvider";
import { Box, Text, Anchor, Menu, Image } from "grommet";
import { User } from "grommet-icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type MainHeaderProps = {
    handleLogout: () => void
};

const MainHeader: FC<MainHeaderProps> = ({ handleLogout }) => {
    const navigate = useNavigate();
    const auth = useAuth();

    return (
        <Box
            elevation="small"
            fill="horizontal"
            // pad={{ vertical: 'xsmall' }}
            background="background-front"

        >
            <Box width={{ max: '1800px', width: '100%' }} margin={{ horizontal: 'auto' }}  >
                {/* <Box flex direction="column"> */}
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    border={{ side: 'bottom' }}
                    pad={{ horizontal: '28px', vertical: '8px' }}

                >
                    <Image src="./assets/images/logo.svg" width={'90px'} onClick={() => navigate("/")} style={{ cursor: 'pointer' }}></Image>

                </Box>
                <Box fill direction="row" justify="between" pad={{ horizontal: '28px', vertical: '3px' }}>
                    <Box
                        direction="row"
                        align="center"
                        gap="small"
                        responsive={false}
                    >
                        <Box direction="column">
                            <Text color="text-strong" weight="bold">Telemetry Central </Text>
                            <Text color="dark-1" weight={'normal'} size="xsmall">
                                HPE Managed Services
                            </Text>
                        </Box>
                    </Box>
                    {/* </Box> */}

                    <Box flex direction="row" align="center" justify="end" gap="1rem">
                        <Anchor
                            label="Dashboard"
                            onClick={() => navigate("/")}
                            style={{ textDecoration: 'none' }}
                            margin={{ end: 'small' }}
                        />

                        {/* <Configure color="black" size="large" /> */}

                        <Menu
                            icon={<User color="black" />}
                            dropProps={{ align: { right: "right", top: "bottom" } }}
                            size="large"
                            items={[[
                                {
                                    label: (
                                        <Box
                                            alignSelf="center"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <Text weight="bold" color={"dark-1"}>{auth?.user}</Text>
                                            <Text size="small" weight={'normal'} color={"dark-1"}>{`Tenant: ${auth.tenant?.tenant?.name}`}</Text>
                                        </Box>
                                    ),
                                },

                            ], [
                                {
                                    label: (
                                        <Box
                                            alignSelf="center"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <Text color={"black"} size="small" weight={'normal'} >Logout</Text>
                                        </Box>
                                    ),
                                    onClick: handleLogout,
                                },
                            ]]}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default MainHeader